
import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

//import bg from '../assets/images/pexels-ksenia-chernaya-3965543HD.jpg';
import icl from '../assets/images/alianzas-estrategicas.jpg';


import Article from '../components/Article';
import SubPageStyles from '../styles/SubPageStyles';
import TeamCard from '../components/TeamCard';

const AlianzaStyles = styled.div`
  .article-body {
    padding: 1rem 10%;
    background-color: #ffffffab;
    backdrop-filter: blur(52px);
      &.header {
        padding: 1rem 3rem;
        background: transparent;
    }
  }

  .aliados-list {
    --cols: 2;
    display: grid;
    grid-template-columns: repeat(var(--cols), 1fr);
    justify-items: center;
    grid-gap: 2rem;
    padding: 0;

  }

  @media (max-width: 1281px) {
    .article-body {
     padding: 1rem 1rem;
     p {
      padding: 0 2rem;
     }
    }
  
  }



  }

  @media (max-width: 800px) {
    height:auto;
    h1 {
      font-size: 3rem;
    }

    .aliados-list {
      --cols: 1;
      display: grid;
      justify-items: center;
      grid-gap: 1rem;
    }
    .article-body {
      padding: 1rem 1rem;
      &.header {
          padding: 1rem 2rem;
          background: transparent;
    }
  }

  }
`;

export default function AlianzasPage() {
  const { site } = useStaticQuery(graphql`
  query {
      site {
        siteMetadata {
          team {
            name
            description
            category
            imgName
          }
        }
      }
    }
`);

const { team = [] } = site.siteMetadata;
  return (
    < AlianzaStyles>
        <Article title="Consultores Aliados">
            <div className="article-body">
              <p>
              Hemos conformado un grupo de selectos profesionales que trabajan como consultores aliados estratégicos para dar acompañamiento a las organizaciones en diferentes campos como <b>calidad, medio ambiente, seguridad industrial, salud ocupacional, productividad, inocuidad alimentaria, MIPG, mercadeo, e-marketing, coaching y desarrollo personal.</b> Estos profesionales tienen una amplia experiencia en empresas nacionales y multinacionales en las áreas de manufactura y servicios, también como auditores en reconocidas certificadoras en Colombia, en la docencia a nivel de empresas y universidades, también como coaches de vida y coaches organizacionales.
              </p>

              <img src={icl} /> 
              <br />
              <h3>Conozca nuestros aliados</h3>
              <br />
              <div className="aliados-list">
                {
                  team.map(
                    t => <TeamCard key={t.name} name={t.name} description={t.description} category={t.category} imgName={t.imgName} />
                  )
                }
              </div>
              <br />
            </div>
        </Article>
    </AlianzaStyles>
  );
}
