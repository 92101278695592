import React from 'react';
import styled from 'styled-components';

const ArticleStyles = styled.article`

  display: block;
  width: 100%;
  height: auto;
  background-position: center;
  background-origin: border-box;
  object-fit: contain;
  background-blend-mode: multiply;

  justify-content: center;
  align-items: center;
  padding: 2rem 0;

  div {
    padding: 1rem 15%;
    /*background-color: var(--white);*/
    &.header {
      color: var(--white);
      padding: 1rem 3rem;
      background: transparent;
      margin-bottom: 5%;
    }
  }

  @media (max-width: 1281px) {
    div {
      padding: 1rem 5%;
    }  
  }

  @media (max-width: 800px) {
    height: auto;
    h1 {
      font-size: 3rem;
    }
    div {
      padding: 1rem 1.2rem;
      &.header {
        padding: 1rem 2rem;
        background: transparent;
      }
    }
  }
`;

export default function Article({ children, title = '' }) {
  return (
    <ArticleStyles className="center">
      <div className="header">
        <h1 className="ft-head">{title}</h1>
        <br />
      </div>

      <div>{children}</div>
    </ArticleStyles>
  );
}
