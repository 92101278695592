import styled from 'styled-components';

const SubPageStyles = styled.article`
  display: block;
  width: 100%;
  height: auto;

  background-position: center;
  background-origin: border-box;
  object-fit: contain;
  background-blend-mode: multiply;
  
  justify-content: center;
  align-items: center;
  text-align: justify;
  padding: 2rem 0;


  ul {
    &.style-scope {
      list-style-type: none;
      li {
        margin: 1rem;
      }
    } 
  }


  div {
    padding: 1rem 15%;
    background-color: var(--white);
      &.header {
            padding: 1rem 3rem;
          background: transparent;
    }
    img {
      padding: 3rem;
      max-width: 680px;
      margin: 0 auto;
    }
  }

  @media (max-width: 800px) {
    height:auto;
    h1 {
      font-size: 3rem;
    }
    div {
    padding: 1rem 2.2rem;
      &.header {
            padding: 1rem 2rem;
          background: transparent;
    }
    img {
        padding: 0;
        max-width: 260px;
        margin: 0 auto;
        }
  }

  }
`;

export default SubPageStyles;