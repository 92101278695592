import React, {useState} from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import MiniLink from './MiniLink';
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import PropTypes from 'prop-types';

import consultoria from '../assets/images/CONSULTORÍA_BL.png';
import auditoria from '../assets/images/AUDITORIA_BL.png';

const TeamCardStyles = styled.div`
  display: grid;
  position: relative;

  padding: 0 0.5rem !important;
  margin-bottom: 1rem;
  grid-template-rows: 10px 8rem 2rem 1fr;
  background-color: white;
  width: 380px;
  max-width: 380px;
  max-height: 80vw;

  .gatsby-image-wrapper {
    border-radius: 50%;
  }

  div {
      padding: 0 0.5rem;
  }

  p {
      font-size: 1.2rem;
      padding: 0.5rem;
  }

  a {
    font-size: 2rem;
    text-decoration: none;
    color: var(--red);
    display: block;

    &:hover {
      color: var(--lightgreen);
      text-decoration: underline;
    }

    @media (max-width: 800px) {
      font-size: 1.5rem;
    }
  }

  @media (max-width: 600px) {
    --columns: 4;
    margin-bottom: 2rem;
    border-bottom: 2px solid var(--grey);
    padding-bottom: 2rem;

    grid-template-rows: 240px 8rem 2rem 1fr;
    background-color: white;
    width: 230px;
    max-width: 230px;
    max-height: 350vw;

    div {
      padding: 0;
  }

  }
  @media (max-width: 500px) {
    --columns: 2;
  }
`;

const Image = ({ imageYouWant }) => {
  const data = useStaticQuery(
    graphql`
      query allTheImagesQuery {
        allImageSharp {
          edges {
            node {
              fixed(width: 220, height: 220) {
                ...GatsbyImageSharpFixed
                originalName
              }
            }
          }
        }
      }`,
  );

  
  const TheImageYouWant = data.allImageSharp.edges
    .filter(edge => {
      return edge.node.fixed.originalName === imageYouWant;
    })
    .map(myImage => <Img fixed={myImage.node.fixed}  objectFit="cover"
    objectPosition="50% 50%" />);

  return (
    <>
      { TheImageYouWant }
    </>
  );
};

Image.propTypes = {
  imageYouWant: PropTypes.string,
};

Image.defaultProps = {
  imageYouWant: '',
};


//<Image imageYouWant={imgName} />
export default function TeamCard({data, active, className, name, description, category, imgName = '' }) {
  const customWidth = {
    width: '100%',
    maxWidth: '100%',
  //  gridColumn: '2 / span 2',
  };

  const [desc, showDesc] = useState(false);

  return (
    <TeamCardStyles>
    <div>

        
    </div>
      <MiniLink borderColor="yellow" color="blue" customWidth={customWidth}>
        <div >
            {name}
        </div>
      </MiniLink>
      <div>
            <span>{category}</span>
        </div>
        <div onClick={() => showDesc(!desc)} >
            {
            desc ? <p>{description}</p> : <div>
                <p>{`${description.substring(0, 120)}...`}</p>
                <p>ver más</p>
            </div>
            }
        </div>

    </TeamCardStyles>
  );
}

/*
export const query = graphql`
  query ImageQuery($toppingRegex: String) {
    file(relativePath: { eq: "foto-manuel1.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 125, height: 125) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }`
;
*/
